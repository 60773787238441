module.exports={
    "images": [
        {
            "title": "La Ermita",
            "file": "ermita.jpg"
        },
        {
            "title": "Cerro del Telégrafo 1",
            "file": "cerro1.jpg"
        },
        {
            "title": "Cerro del Telégrafo 2",
            "file": "cerro2.jpg"
        },
        {
            "title": "Casa en el lago",
            "file": "casaLago.jpg"
        },
        {
            "title": "Paisaje invernal",
            "file": "invierno.jpg"
        },
        {
            "title": "Paisaje invernal 2",
            "file": "invierno2.jpg"
        },
        {
            "title": "Lago",
            "file": "lago.jpg"
        },
        {
            "title": "Playa",
            "file": "playa.jpg"
        },
        {
            "title": "Postes teléfono",
            "file": "postes.jpg"
        },
        {
            "title": "Mujer tumbada",
            "file": "tumbada.jpg"
        },
        {
            "title": "Cerezas",
            "file": "cerezas.jpg"
        },
        {
            "title": "Martín 1",
            "file": "martin1.jpg"
        },
        {
            "title": "Martín 2",
            "file": "martin2.jpg"
        },
        {
            "title": "Sofía 1",
            "file": "sofia1.jpg"
        },
        {
            "title": "Sofía 2",
            "file": "sofia2.jpg"
        },
        {
            "title": "Leia",
            "file": "leia.jpg"
        },
        {
            "title": "Retrato 1",
            "file": "retrato1.jpg"
        },
        {
            "title": "Retrato 2",
            "file": "retrato2.jpg"
        },
        {
            "title": "Retrato 3",
            "file": "retrato3.jpg"
        },
        {
            "title": "Retrato 4",
            "file": "retrato4.jpg"
        },
        {
            "title": "Retrato 5",
            "file": "retrato5.jpg"
        },
        {
            "title": "Retrato 6",
            "file": "retrato6.jpg"
        },
        {
            "title": "Retrato 7",
            "file": "retrato7.jpg"
        },
        {
            "title": "Retrato 8",
            "file": "retrato8.jpg"
        },
        {
            "title": "Retrato 9",
            "file": "retrato9.jpg"
        },
        {
            "title": "Retrato 10",
            "file": "retrato10.jpg"
        },
        {
            "title": "Retrato 11",
            "file": "retrato11.jpg"
        },
        {
            "title": "Retrato 12",
            "file": "retrato12.jpg"
        },
        {
            "title": "Rali",
            "file": "rali.jpg"
        },
        {
            "title": "Angel",
            "file": "angel.jpg"
        },
        {
            "title": "Playa 2",
            "file": "playa2.jpg"
        },
        {
            "title": "Playa 3",
            "file": "playa3.jpg"
        },
        {
            "title": "Modelo 1",
            "file": "modelo1.jpg"
        },
        {
            "title": "Modelo 2",
            "file": "modelo2.jpg"
        },
        {
            "title": "Modelo 3",
            "file": "modelo3.jpg"
        },
        {
            "title": "Modelo 4",
            "file": "modelo4.jpg"
        },
        {
            "title": "Modelo 5",
            "file": "modelo5.jpg"
        },
        {
            "title": "Modelo 6",
            "file": "modelo6.jpg"
        },
        {
            "title": "Modelo 7",
            "file": "modelo7.jpg"
        },
        {
            "title": "Modelo 8",
            "file": "modelo8.jpg"
        },
        {
            "title": "Modelo 9",
            "file": "modelo9.jpg"
        },
        {
            "title": "Modelo 10",
            "file": "modelo10.jpg"
        },
        {
            "title": "Modelo 11",
            "file": "modelo11.jpg"
        },
        {
            "title": "Modelo 12",
            "file": "modelo12.jpg"
        },
        {
            "title": "Modelo 13",
            "file": "modelo13.jpg"
        },
        {
            "title": "Pájaros",
            "file": "pajaros.jpg"
        },
        {
            "title": "Fruta",
            "file": "fruta.jpg"
        },
        {
            "title": "Fruta 2",
            "file": "fruta2.jpg"
        },
        {
            "title": "Fruta 3",
            "file": "fruta3.jpg"
        },
        {
            "title": "Ventana",
            "file": "ventana.jpg"
        }
    ]
}